.publication-entry {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 1rem;
  // margin-bottom: 1.5rem;
  // padding: 1rem;
  padding-top: 0.5rem; /* Add padding to the top */
  padding-bottom: 1.5rem; /* Add padding to the bottom */
  // border: 1px solid #ddd;
  // border-radius: 0.25rem;
  align-items: start;
}

.special-entry .publication-image {
  width: 70%; /* Adjust the width as needed */
  height: auto;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  margin-left: auto; /* Center the image horizontally */
  margin-right: auto; /* Center the image horizontally */
}

.eighty-entry .publication-image {
  width: 80%; /* Adjust the width as needed */
  height: auto;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  margin-left: auto; /* Center the image horizontally */
  margin-right: auto; /* Center the image horizontally */
}

.publication-image-container {
  display: flex;
  margin-top: 0.5rem;
  // align-items: center;
  align-items: flex-start; /* Align the image at the top */
}

.publication-image {
  width: 100%; /* Ensure the image takes up the full width of the container */
  height: auto;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
}

.publication-entry a {
  font-size: 1.0rem;
  text-decoration: none;
  color: inherit;
}

.publication-entry {
  font-size: 0.9rem;
}

.publication-entry .buttons {
  margin-top: 0.2rem;
}

.publication-entry .button {
  @extend .button;
  font-size: 0.9rem; /* Adjust the size of the icons */
  // display: inline-block;
  padding: 0.4rem 0.55rem;
  // border: 1px solid #17a2b8;
  // border-radius: 0.25rem;
  // color: #17a2b8;
  // text-decoration: none;
  // transition: background-color 0.3s ease;
  // margin-right: 0.5rem;
}

// .publication-entry .button:hover {
//   background-color: #17b81f !important;
//   color: #fff !important;
// }

.pdf-button {
  @extend .button;
  @extend .button--outline-info;
  @extend .button--rounded;
  // color: #b82a17;
  // border: 1px solid #b82a17;
  // color: #b82a17 !important; /* Use !important to ensure it overrides other styles */
  // border: 1px solid #b82a17 !important; /* Use !important to ensure it overrides other styles */
  // display: inline-block;
  // padding: 0.5rem 1rem;
  // border: 1px solid #17a2b8;
  // border-radius: 0.25rem;
  // color: #17a2b8;
  // text-decoration: none;
  // transition: background-color 0.3s ease;
  // margin-right: 0.5rem;
  // position: relative;
  // font-size: 16px; /* Ensure the font size is set */
}

.pdf-button::before {
  content: "\f1c1"; /* Unicode for the Font Awesome PDF icon */
  font-family: "Font Awesome 6 Free"; /* Ensure you are using the correct Font Awesome version */
  font-weight: 300; /* Font weight for solid icons 900, 400, 300*/
  // margin-right: 0.5rem; /* Space between icon and text */
}

.youtube-button {
  @extend .button;
  @extend .button--outline-info;
  @extend .button--rounded;
}

.youtube-button::before {
  content: "\f167"; /* Unicode for the Font Awesome PDF icon */
  font-family: "Font Awesome 6 Brands"; /* Ensure you are using the correct Font Awesome version */
  font-weight: 300; /* Font weight for solid icons 900, 400, 300*/
}

.publication-entry .github-button {
  @extend .button;
  @extend .button--outline-info;
  @extend .button--rounded;
}

.arxiv-button {
  @extend .button;
  @extend .button--outline-info;
  @extend .button--rounded;
}

.doi-button {
  @extend .button;
  @extend .button--outline-info;
  @extend .button--rounded;
}

.publication-entry .website-button {
  @extend .button;
  @extend .button--outline-warning;
  @extend .button--rounded;
}

.publication-entry .website-button::before {
  content: "\f5fc"; /* Unicode for the Font Awesome PDF icon */
  font-family: "Font Awesome 6 Free"; /* Ensure you are using the correct Font Awesome version */
  font-weight: 900; /* Font weight for solid icons 900, 400, 300*/
  margin-right: 0.4rem; /* Space between icon and text */
}

.publication-entry .button--outline-info {
  @extend .button--outline-info;
  // border-color: #17a2b8;
  // color: #17a2b8;
}

.publication-entry .button--outline-warning {
  @extend .button--outline-warning;
  // border-color: #ffc107;
  // color: #ffc107;
}

// .publication-entry .button--rounded {
//   @extend .button--rounded;
//   // border-radius: 0.25rem;
// }