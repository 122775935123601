ul.toc {
  display: block;
  margin: 0;
  color: $text-color;
  list-style-type: none;
  & > li {
    margin: calc(map-get($spacers, 1) / 2) 0;
    a {
      display: inline-block;
      margin: calc(map-get($spacers, 1) / 4) 0;
      text-decoration: none !important;
    }
  }
  .toc-h1,
  .toc-h2,
  .toc-h3,
  .toc-h4,
  .toc-h5,
  .toc-h6 {
    a {
      @include link-colors($text-color, $main-color-1);
    }
    &.active {
      a {
        @include link-colors($main-color-1);
      }
    }
  }

  .toc-h2,
  .toc-h3,
  .toc-h4,
  .toc-h5,
  .toc-h6 {
    &, a {
      font-size: map-get($base, font-size-xs);
      font-weight: map-get($base, font-weight);
      line-height: map-get($base, line-height-xs);
    }
  }
  .toc-h1 {
    @include split-line(bottom);
    padding: map-get($spacers, 2) 0 map-get($spacers, 1) 0;
    margin-bottom: map-get($spacers, 2);
    color: $text-color-d;
    &, a {
      font-size: map-get($base, font-size-sm);
      font-weight: map-get($base, font-weight-bold);
      line-height: map-get($base, line-height-sm);
    }
    a {
      @include link-colors($text-color-d, $main-color-1);
    }
  }
  .toc-h2 {
    &, a {
      font-weight: map-get($base, font-weight-bold);
    }
  }
  .toc-h3 {
    margin-left: map-get($spacers, 3);
  }
  .toc-h4 {
    margin-left: map-get($spacers, 3) * 2;
  }
  .toc-h5,
  .toc-h6 {
    margin-left: map-get($spacers, 3) * 3;
  }
  .toc-h6 {
    color: $text-color-l;
    a {
      @include link-colors($text-color-l, $main-color-1);
    }
  }
}

ul.toc--ellipsis {
  & > li {
    @include overflow(hidden);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

ul.toc--navigator {
  & > li {
    a {
      padding-left: map-get($spacers, 2);
      margin: map-get($spacers, 1) 0;
    }
    &.active {
      a {
        margin-left: -4px;
        @include split-line(left, 4px, $main-color-1);
      }
    }
  }
  .toc-h2,
  .toc-h3,
  .toc-h4 {
    color: $text-color-l;
    a {
      @include link-colors($text-color-l);
    }
  }
  .toc-h1 {
    &, a {
      font-size: map-get($base, font-size);
      line-height: map-get($base, line-height);
    }
    color: $text-color;
    a {
      @include link-colors($text-color-d);
    }
  }
  .toc-h2 {
    &, a {
      font-size: map-get($base, font-size-sm);
      font-weight: map-get($base, font-weight-bold);
      line-height: map-get($base, line-height-sm);
    }
  }
}
